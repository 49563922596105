import React, { useState, useMemo, useEffect, useCallback } from 'react'
import moment from 'moment'
import axios from 'axios'
import { config } from '../assets/config/config'
import ThemedSuspense from '../components/ThemedSuspense'
import { identifyBrevoUser } from '../utils/brevo'

const apiUrl = config.api.url

// create context
export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false)
  const [user, setUser] = useState(null)
  const [isImpersonating, setImpersonating] = useState(false)
  const [accessToken, setAccessToken] = useState(null)
  const [timeoutRef, setTimeoutRef] = useState(null)

  const refreshTokens = useCallback(
    () => {
      return axios.post(`${apiUrl}/v1/auth/refresh-tokens`, {})
      .then(response => {
        setAccessToken(response.data.token)
        setImpersonating(false)
        setUser(response.data.user)
        return response
      })
      .catch(error => {
        setUser(null)
        setAccessToken(null)
        return error
      })
    },
    []
  )

  const refreshUser = useCallback(
    () => {
      if (!user) return;
      
      return axios.get(`${apiUrl}/v1/users/${user.id}`)
      .then(response => {
        if (response.status === 200 && response.data) {
          identifyBrevoUser(response.data.email)
          if (JSON.stringify(user) != JSON.stringify(response.data)) {
            setUser(response.data)
          }
        }
      })
    },
    [user]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      refreshUser();
    }, 2000);

    return () => clearInterval(interval);
  }, [refreshUser]);

  const syncLogout = (event) => {
    if (event.key === 'logout') {
      setAccessToken(null)
      setUser(null)
    }
  }

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      config => {
        config.withCredentials = true
        config.credentials = 'include'
        if(accessToken) {
          config.headers.Authorization = `Bearer ${accessToken.token}`
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.request.eject(interceptorId);
    }
  }, [accessToken])

  useEffect(() => {
    refreshTokens()
    .then(response => {
      setLoaded(true)
    })
  }, [refreshTokens])

  useEffect(() => {
    if(accessToken) {
      const tokenExpires = moment(accessToken.expires)
      const tokenMaxAge = tokenExpires.diff(moment().add(1, 'minutes'))
      const ref = setTimeout(() => {
        refreshTokens()
      }, tokenMaxAge)
      return () => clearTimeout(ref)
    }
  }, [accessToken, refreshTokens])

  useEffect(() => {
    window.addEventListener('storage', syncLogout) 
    return function cleanup() {
      window.removeEventListener('storage', syncLogout)
    }
  }, [])

  const value = useMemo(
    () => {
      const register = (username, email, password) => {
        return axios.post(`${apiUrl}/v1/auth/register`, {
          name: username,
          email: email,
          password: password
        })
        .then(response => {
          setAccessToken(response.data.token)
          setUser(response.data.user)
        })
      }

      const login = (email, password) => {
        return axios.post(`${apiUrl}/v1/auth/login`, {
          email: email,
          password: password
        })
        .then(response => {
          setAccessToken(response.data.token)
          setUser(response.data.user)
        })
      }

      const githubAuth = () => {
        window.location.href = `${apiUrl}/v1/auth/github`;
      }

      const googleAuth = () => {
        window.location.href = `${apiUrl}/v1/auth/google`;
      }

      const logout = () => {
        setAccessToken(null)
        setUser(null)
        return axios.post(`${apiUrl}/v1/auth/logout`, {})
        .then(response => {
          window.localStorage.setItem('logout', moment())
        })
        .catch(err => {})
      }

      const forgotPassword = (email) => {
        return axios.post(`${apiUrl}/v1/auth/forgot-password`, {
          email: email
        })
      }

      const resetPassword = (password, resetToken) => {
        return axios.post(`${apiUrl}/v1/auth/reset-password?token=${resetToken}`, {
          password: password
        })
      }

      const resendVerificationEmail = (userId) => {
        return axios.post(`${apiUrl}/v1/auth/resend-verification-email`, {
          userId: userId,
        })
      }

      const verifyEmail = (emailVerificationToken) => {
        return axios.post(`${apiUrl}/v1/auth/verify-email?token=${emailVerificationToken}`, {})
      }

      const impersonateUser = (userId) => {
        if (isImpersonating) return
        return axios.post(`${apiUrl}/v1/auth/impersonate-user/${userId}`, {})
        .then(response => {
          setImpersonating(true)
          setAccessToken(response.data.token)
          setUser(response.data.user)
        })
      }

      const stopImpersonation = () => {
        if (!isImpersonating) return;
        refreshTokens()
        .then(() => {
          setImpersonating(false)
        })
      }

      return ({
        user,
        setUser,
        register,
        login,
        githubAuth,
        googleAuth,
        logout,
        forgotPassword,
        resetPassword,
        resendVerificationEmail,
        verifyEmail,
        isImpersonating,
        impersonateUser,
        stopImpersonation,
      })
    },
    [user, isImpersonating]
  )

  if(!isLoaded) {
      return <ThemedSuspense />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
