export function identifyBrevoUser(email) {
    updateUserInBrevoChat(email)
    if (window.sendinblue && window.sendinblue.identify) {
        window.sendinblue.identify(email, {
            // can add additional properties like this
            // 'FIRSTNAME': firstName,
            // 'LASTNAME': lastName,
            // 'plan': plan,
            // 'location': location
        });
    }
}


export function trackBrevoPage(pageName) {
    if (window.sendinblue && window.sendinblue.track) {
        window.sendinblue.page(pageName,{
            // can add additional properties like this
        });
    }
}


export function trackBrevoEvent(eventName, eventProperties) {
    if (window.sendinblue && window.sendinblue.track) {
        window.sendinblue.track(eventName, eventProperties, {
            "id": eventName,
            "data": eventProperties
        })
    }
}

export function updateUserInBrevoChat(email) {
    if (window.BrevoConversations) {
        window.BrevoConversations('updateIntegrationData', {
            email: email,
        });
    }
}